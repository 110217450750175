<template>
  <component :is="slot" :index="index" />
</template>

<script>

const LeaderboardAd = () => import('@/components/Ads/Static/LeaderboardAd.vue');
const RecordAd = () => import('@/components/Ads/Static/RecordAd.vue');
const ResultsAd = () => import('@/components/Ads/Static/ResultsAd.vue');

export default {
  props: {
    adSlot: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  computed: {
    slot() {
      switch (this.adSlot) {
        case 'LeaderboardAd':
          return LeaderboardAd;
        case 'RecordAd':
          return RecordAd;
        case 'ResultsAd':
          return ResultsAd;
        default:
          return '';
      }
    },
  },
};
</script>
